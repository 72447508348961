import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CounterpartiesActions } from './actions';
import { catchError, combineLatest, map, mergeMap, of, switchMap } from 'rxjs';
import { AppState } from 'src/app/core/store/state';
import { DialogsActions } from '../../dialogs/store/actions';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationsActions } from '../../notifications/store/actions';
import { PartiesApiService } from '../services/parties-api.service';

@Injectable()
export class FeatureEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private readonly router: Router,
    private partiesApiService: PartiesApiService,
    private dialogRef: MatDialog,
    private translocoService: TranslocoService,
  ) {}

  getCounterparties$ = createEffect(() =>
    combineLatest([this.actions$.pipe(ofType(CounterpartiesActions.getCounterparties))]).pipe(
      map(() => {
        return CounterpartiesActions.loadCounterparties();
      }),
    ),
  );

  loadCounterparties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.loadCounterparties),
      switchMap(() => {
        return this.partiesApiService.getParties$().pipe(
          map((counterparties) => CounterpartiesActions.setCounterparties({ counterparties })),
          catchError((response) => {
            return of(
              CounterpartiesActions.loadCounterpartiesFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'Counterparties api not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  createCounterparty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.createCounterparty),
      mergeMap((props) =>
        this.partiesApiService.createParty$(props.model.name).pipe(
          map((counterparty) => CounterpartiesActions.createCounterpartySuccess({ counterparty })),
          catchError((response) => {
            return of(
              CounterpartiesActions.createCounterpartyFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: this.translocoService.translate('errors.createCounterparty'),
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  createCounterpartySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.createCounterpartySuccess),
      map((props) => {
        this.store.dispatch(CounterpartiesActions.closeCreatingCounterpartyModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.counterpartyAdded', {
              name: props.counterparty.name,
            }),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return CounterpartiesActions.getCounterparties();
      }),
    ),
  );

  createCounterpartyCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.createCounterpartyCancel),
      map((props) => {
        return CounterpartiesActions.createCounterpartyCancelled();
      }),
    ),
  );

  deleteCounterparty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.deleteCounterparty),
      map((props) => {
        return DialogsActions.showDialog({
          data: {
            actionsKeys: ['yes', 'no'],
            content: '',
            title: this.translocoService.translate('modalBody.confirmDeleteCounterparty'),
          },
          callback: (action) => {
            if (action === 'yes') {
              return this.store.dispatch(
                CounterpartiesActions.deleteCounterpartyConfirmed({ id: props.id }),
              );
            } else {
              return of(
                DialogsActions.setRef({ ref: null }),
                CounterpartiesActions.deleteCounterpartyCancel(),
              );
            }
          },
        });
      }),
    ),
  );

  deleteCounterpartyConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.deleteCounterpartyConfirmed),
      mergeMap((props) => {
        return this.partiesApiService.deleteParty$(props.id).pipe(
          map(() => CounterpartiesActions.deleteCounterpartySuccess()),
          catchError((response) => {
            return of(
              DialogsActions.setRef({ ref: null }),
              CounterpartiesActions.deleteCounterpartyFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'Counterparties API not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  deleteCounterpartySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.deleteCounterpartySuccess),
      map(() => {
        return CounterpartiesActions.getCounterparties();
      }),
    ),
  );

  loadCounterparty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.loadCounterparty),
      switchMap(({ id }) => {
        return this.partiesApiService.getPartyById$(id).pipe(
          map((counterparty) => CounterpartiesActions.setCounterparty({ counterparty })),
          catchError((response) => {
            return of(
              CounterpartiesActions.loadCounterpartyFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'Counterparties api not available',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  editCounterpartyCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.editCounterpartyCancel),
      map((props) => {
        return CounterpartiesActions.editCounterpartyCancelled();
      }),
    ),
  );

  editCounterparty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.editCounterparty),
      mergeMap((props) =>
        this.partiesApiService.updateParty$(props.model.name, props.id).pipe(
          map((counterparty) => CounterpartiesActions.editCounterpartySuccess({ counterparty })),
          catchError((response) => {
            return of(
              CounterpartiesActions.editCounterpartyFail(),
              DialogsActions.showDialog({
                data: {
                  actionsKeys: ['close'],
                  content: '',
                  title: response.error || 'Counterparties api not available',
                },
              }),
            );
          }),
        ),
      ),
    ),
  );

  editCounterpartySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CounterpartiesActions.editCounterpartySuccess),
      map((props) => {
        this.store.dispatch(CounterpartiesActions.closeEditingCounterpartyModal());
        this.store.dispatch(
          NotificationsActions.showNotification({
            message: this.translocoService.translate('text.partyUpdated', {
              name: props.counterparty.name,
            }),
            action: 'x',
          }),
        );

        this.dialogRef.closeAll();
        return CounterpartiesActions.getCounterparties();
      }),
    ),
  );
}
