import { Injectable } from '@angular/core';
import { BaseEventsService } from 'src/app/core/services/base-event-service';
import { eventServicesList } from 'src/app/core/services/event-services-list';

@Injectable({
  providedIn: 'root',
})
export class CounterpartiesListEventsService extends BaseEventsService {
  constructor() {
    super(eventServicesList.counterpartiesList);
  }
}
