import { PartyDto } from '../services/parties-api.service';

export interface CounterpartiesState {
  counterparties: PartyDto[] | null;
  loadingCounterparties: boolean;
  creatingCounterparty: boolean;
  closeCreatingCounterpartyModal: boolean;
  deletingCounterparty: boolean;
  counterparty: PartyDto | null;
  loadingCounterparty: boolean;
  editingCounterparty: boolean;
  closeEditingCounterpartyModal: boolean;
}

export const initialState: CounterpartiesState = {
  counterparties: null,
  loadingCounterparties: false,
  creatingCounterparty: false,
  closeCreatingCounterpartyModal: false,
  deletingCounterparty: false,
  counterparty: null,
  loadingCounterparty: false,
  editingCounterparty: false,
  closeEditingCounterpartyModal: false,
};
