import { CounterpartiesComponent } from './components/counterparties/counterparties.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material.module';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { LetDirective } from 'src/app/core/utils/let-directive';
import { CounterpartiesRoutingModule } from './counterparties-routing.module';
import { CounterpartiesStoreModule } from './store/module';
import { CounterpartiesListComponent } from './components/counterparties-list/counterparties-list.component';
import { AddCounterpartyModalComponent } from './components/modals/add-counterparty-modal/add-doc-type-modal.component';
import { EditCounterpartyModalComponent } from './components/modals/edit-counterparty-modal/edit-counterparty-modal.component';

@NgModule({
  declarations: [
    CounterpartiesComponent,
    CounterpartiesListComponent,
    AddCounterpartyModalComponent,
    EditCounterpartyModalComponent,
  ],
  imports: [
    CounterpartiesRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    CommonModule,
    AngularMaterialModule,
    MatIconModule,
    TranslocoModule,
    PipesModule,
    CounterpartiesStoreModule,
    LetDirective,
  ],
})
export class CounterpartiesModule {}
