import { createReducer, on } from '@ngrx/store';
import { CounterpartiesActions } from './actions';
import { initialState } from './state';

export const featureReducer = createReducer(
  initialState,
  on(CounterpartiesActions.setCounterparties, (state, { counterparties }) => ({
    ...state,
    counterparties: counterparties,
    loadingCounterparties: false,
  })),
  on(CounterpartiesActions.loadCounterparties, (state, {}) => ({
    ...state,
    loadingCounterparties: true,
  })),
  on(CounterpartiesActions.loadCounterpartiesFail, (state, {}) => ({
    ...state,
    loadingCounterparties: false,
  })),
  on(CounterpartiesActions.createCounterparty, (state, {}) => ({
    ...state,
    creatingCounterparty: true,
    closeCreatingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.createCounterpartyCancel, (state, {}) => ({
    ...state,
    creatingCounterparty: false,
    closeCreatingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.createCounterpartySuccess, (state, {}) => ({
    ...state,
    creatingCounterparty: false,
    closeCreatingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.createCounterpartyFail, (state, {}) => ({
    ...state,
    creatingCounterparty: false,
    closeCreatingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.createCounterpartyCancelled, (state, {}) => ({
    ...state,
    closeCreatingCounterpartyModal: true,
  })),
  on(CounterpartiesActions.closeCreatingCounterpartyModal, (state, {}) => ({
    ...state,
    closeCreatingCounterpartyModal: true,
  })),
  on(CounterpartiesActions.clearCreateCounterpartyCancelled, (state, {}) => ({
    ...state,
    closeCreatingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.deleteCounterparty, (state, {}) => ({
    ...state,
    deletingCounterparty: true,
  })),
  on(CounterpartiesActions.deleteCounterpartyCancel, (state, {}) => ({
    ...state,
    deletingCounterparty: false,
  })),
  on(CounterpartiesActions.deleteCounterpartySuccess, (state, {}) => ({
    ...state,
    deletingCounterparty: false,
  })),
  on(CounterpartiesActions.deleteCounterpartyFail, (state, {}) => ({
    ...state,
    deletingCounterparty: false,
  })),
  on(CounterpartiesActions.setCounterparty, (state, { counterparty }) => ({
    ...state,
    counterparty: counterparty,
    loadingCounterparty: false,
  })),
  on(CounterpartiesActions.loadCounterparty, (state, {}) => ({
    ...state,
    loadingCounterparty: true,
  })),
  on(CounterpartiesActions.loadCounterpartyFail, (state, {}) => ({
    ...state,
    loadingCounterparty: false,
  })),
  on(CounterpartiesActions.editCounterparty, (state, {}) => ({
    ...state,
    editingCounterparty: true,
    closeEditingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.editCounterpartyCancel, (state, {}) => ({
    ...state,
    editingCounterparty: false,
    closeEditingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.editCounterpartySuccess, (state, {}) => ({
    ...state,
    editingCounterparty: false,
    closeEditingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.editCounterpartyFail, (state, {}) => ({
    ...state,
    editingCounterparty: false,
    closeEditingCounterpartyModal: false,
  })),
  on(CounterpartiesActions.editCounterpartyCancelled, (state, {}) => ({
    ...state,
    counterparty: null,
    closeEditingCounterpartyModal: true,
  })),
  on(CounterpartiesActions.closeEditingCounterpartyModal, (state, {}) => ({
    ...state,
    counterparty: null,
    closeEditingCounterpartyModal: true,
  })),
  on(CounterpartiesActions.clearEditCounterpartyCancelled, (state, {}) => ({
    ...state,
    closeEditingCounterpartyModal: false,
  })),
);
