import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import {
  selectCloseCreatingCounterpartyModal,
  selectCounterpartyCreating,
} from '../../../store/selectors';
import { CounterpartiesActions } from '../../../store/actions';
import { PartyDto } from '../../../services/parties-api.service';
import { UserDto } from 'src/app/modules/login/services/user-api.service';

@UntilDestroy()
@Component({
  selector: 'app-counterparty-modal',
  templateUrl: './add-counterparty-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCounterpartyModalComponent implements OnInit, AfterViewInit {
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public title: string = this.translocoService.translate(`titles.addCounterparty`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  public user$: Observable<UserDto | null> | undefined;
  public currentUser: UserDto | null | undefined;
  public isCounterpartyCreating$ = this.store$.select(selectCounterpartyCreating);

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.store$
      .select(selectCloseCreatingCounterpartyModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(CounterpartiesActions.clearCreateCounterpartyCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      name: ['', { validators: [Validators.required] }],
    });

    this.cdr.markForCheck();
  }

  public onConfirm(): void {
    const model = this.form?.getRawValue() as PartyDto;

    this.store$.dispatch(CounterpartiesActions.createCounterparty({ model }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(CounterpartiesActions.createCounterpartyCancel({ pristine }));
  }
}
