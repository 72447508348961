import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataTableItem } from 'src/app/shared/components/data-table/client-side-data-table/client-side-data-table.component';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ThreeDotsMenuPipe } from 'src/app/shared/pipes/three-dots-menu.pipe';
import { TranslocoService } from '@ngneat/transloco';
import { CounterpartiesListEventsService } from './counterparties-list-events.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EventsServiceAction, EventsServiceData } from 'src/app/core/services/base-event-service';
import { dataTableEvents } from 'src/app/core/constants/data-table-events';
import { MyPositionsPipe } from 'src/app/shared/pipes/my-position.spipe';
import { PartyDto } from '../../services/parties-api.service';
import { selectCounterparties } from '../../store/selectors';
import { CounterpartiesActions } from '../../store/actions';
import { EditCounterpartyModalComponent } from '../modals/edit-counterparty-modal/edit-counterparty-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-counterparties-list',
  templateUrl: './counterparties-list.component.html',
  styleUrl: './counterparties-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterpartiesListComponent implements OnInit {
  public data: PartyDto[] | undefined;
  public columns!: DataTables.ColumnSettings[];
  public sort!: (string | number)[][];
  public dataTableMenuItems!: DataTableItem[];
  public refreshDataTable: BehaviorSubject<any> = new BehaviorSubject(null);
  public data$: any;

  constructor(
    private readonly store$: Store,
    public threeDotsMenuPipe: ThreeDotsMenuPipe,
    public myPositionsPipe: MyPositionsPipe,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
    public counterpartiesListEventsService: CounterpartiesListEventsService,
    public bsModalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.fetchData();
  }

  fetchData(): void {
    this.data$ = this.store$
      .select(selectCounterparties)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.initTable(data);
        this.refreshDataTable.next(this.columns);
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.data) {
        this.initTable(this.data);
        this.refreshDataTable.next(this.columns);
      }
    });

    this.counterpartiesListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.Edit: {
            this.edit(data.id);
            break;
          }
          case EventsServiceAction.Delete: {
            this.delete(data.id);
            break;
          }
        }
      });
  }

  edit(id: string): void {
    this.store$.dispatch(CounterpartiesActions.loadCounterparty({ id }));

    this.bsModalService.show(EditCounterpartyModalComponent, {
      initialState: {
        id,
      },
      class: 'modal-lg',
    });
  }

  delete(id: string): void {
    this.store$.dispatch(CounterpartiesActions.deleteCounterparty({ id }));
  }

  initColumns(): void {
    const that = this;
    this.columns = Object.assign([
      {
        title: this.translocoService.translate('columns.counterparty'),
        data: 'name',
      },
      {
        width: '50px',
        data: 'id',
        searchable: false,
        orderable: false,
        render: function (data: any, type: any, full: any) {
          return that.threeDotsMenuPipe.transform(data, that.dataTableMenuItems);
        },
      },
    ]);
  }

  initTable(data: PartyDto[] | null): void {
    this.initColumns();
    this.dataTableMenuItems = [
      {
        title: this.translocoService.translate('actions.editProperties'),
        event: dataTableEvents.common.edit,
        icon: 'edit_square',
      },
      {
        title: this.translocoService.translate('actions.remove'),
        event: dataTableEvents.common.delete,
        icon: 'delete',
      },
    ];
    this.sort = [[0, 'asc']];
    this.data = data || [];
  }
}
